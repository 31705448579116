/* eslint-disable eqeqeq */
<template>
 
  <div class="account-list">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.query" placeholder="店铺名称/账号" style="width: 160px" clearable></el-input>
        <el-date-picker v-model="time" class="ml20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          value-format='yyyy-MM-dd '>
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>
      <PrevReclick >
         <el-button type="primary" class="sure" @click="exportExcle">导出</el-button>
        </PrevReclick >
     
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="createTime" label="申请时间"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称"></el-table-column>
        <el-table-column prop="tel" label="店铺账号"></el-table-column>
        <el-table-column prop="uploadQualificationStateText" label="开户状态">
          <template slot-scope="scope">
          <span>{{unloadList[scope.row.uploadQualificationState]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rejectMessage" label="失败原因">
        </el-table-column>
        <el-table-column prop="verifyName" label="业务经受人" width="160">
        </el-table-column>
        <el-table-column prop="pakage" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <span class="account-oprate-but" @click="accountDetail('2',scope.row,1)"
             v-if="(scope.row.uploadQualificationState==1)">提交</span>
            <span class="account-oprate-but" @click="accountDetail('1',scope.row,2)"
              v-if="scope.row.uploadQualificationState==4||scope.row.uploadQualificationState==5||scope.row.uploadQualificationState==6">
              详情</span>
            <el-divider direction="vertical"  v-if="scope.row.uploadQualificationState==4">
            </el-divider><span class="account-oprate-but" @click="accountDetail('2',scope.row)"
              v-if="scope.row.uploadQualificationState==4">更改状态</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  
  </div>
 
</template>
<script>
import {
  $sp_adList,
 
} from '@/api/ad';

import { exportFileSync } from '@base/utils';

const unloadList = ['未开户', '待提交', '', '', '待微信审核', '审核成功', '审核失败'];

export default {
  data() {
    return {
      unloadList,
      form: {
        orderId: '',
        spreadId: '',
        agentSid: '',
        shopId: '',
        payStatus: '',
        query: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      payStatusList: [
        {
          value: 2,
          label: '已支付',
        },
        {
          value: 3,
          label: '支付后退款',
        },
      ],
      dialogs: {},
      id: '',
    };
  },
  components: {
  
  },
  created() {
    this.getTableData({ pageSize: 10, pageCurrent: this.currentPage });
  },
  mounted() {

  },
  methods: {
    // 导出列表
    exportExcle() {
      if ((!this.form.query) && !this.time) {
        this.$message.error('请输入店铺名称账号，或者日期');
        return;
      }
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      // $sp_exportExcle({ query: this.form.query, startTime, endTime }).then(() => {

      // });
      exportFileSync(
        '/j/platform/t-wx-localad-shop/list/export',
        {
          query: this.form.query,
          startTime,
          endTime,
          
        },
        'get',
        'qrcode_download.zip',
        this.onDownloadProgress,
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    onDownloadProgress(e) {
      console.log(e, 'wodingnigefei');
    }, 
    // 跳转审核页面
    accountDetail(item, op, state) {
      console.log(state, '345');
      this.id = op.id;
      // eslint-disable-next-line eqeqeq
      if (state == 1) {
        this.$router.push({
          name: 'AccountAudit',
          query: { state: item, id: op.id, unload: 'orgin' },
        });
        // this.$refs.auditPage.dialogs = {
        //   icon: '/img/ad/printSucess.png',
        //   title: '提示',
        //   content: '确定已将资料提交至微信审核?若资料有误请线下联系商户更改相关资料。',
        // };
        // this.$refs.auditPage.showDialog = true;
      // eslint-disable-next-line eqeqeq
      } else if (state == 2) {
        this.$router.push({
          name: 'AccountAudit',
          query: {
            state: item, id: op.id, show: true, unload: 'orgin', 
          },
        });
      } else {
        this.$router.push({
          name: 'AccountAudit',
          query: { state: item, id: op.id },
        });
      }
    },
    // 筛选
    search() {
      this.currentPage = 1;
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      this.getTableData({
        pageSize: 10, pageCurrent: this.currentPage, query: this.form.query, startTime, endTime,
      });
    },
    // 清空
    clear() {
      this.currentPage = 1;
      this.form.query = '';
      this.time = '';
      this.getTableData({ pageSize: 10, pageCurrent: this.currentPage });
    },
    // 请求列表数据
    getTableData(params = {}) {
      $sp_adList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 翻页
    pageChange() {
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      this.getTableData({
        pageSize: 10, pageCurrent: this.currentPage, query: this.form.query, startTime, endTime,
      });
    },
    offDialog() {
      this.$refs.auditPage.showDialog = false;
    },
   
  },
};
</script>
<style lang="scss" >
.account-list {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table__body-wrapper{
    min-height: 510px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .account-oprate-but {
    cursor: pointer;
    color: #ef3f46;
  }
  .audit-footer-button {
    margin: 20px auto;
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
}
</style>

